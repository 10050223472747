import { Box, Typography } from "@mui/material";

function SectionHeader(props) {
  const {
    superheader,
    title,
    subtitle,
    size = 4,
    // className,
    // fontWeight,
    bgcolor,
    styles,
    bgImage,
    ...otherProps
  } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      sx={{
        width: "100%",
        padding: "7px 3px",
        borderRadius: "5px",
        backgroundColor: bgcolor,
        backgroundImage: bgImage,
        ...styles,
        // "&:not(:last-child)": {
        //   marginBottom: "2rem",
        // },
      }}
      {...otherProps}
    >
      {superheader && (
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            fontSize: "0.8rem",
            maxWidth: 700,
            display: "inline-block",
            textTransform: "uppercase",
          }}
        >
          {superheader}
        </Typography>
      )}

      {title && (
        <Typography
          variant={`h${size}`}
          gutterBottom={props.subtitle ? true : false}
          sx={{
            fontSize: {
              xs: "1.4rem",
              md: "2rem",
            },
            fontWeight: 500,
          }}
        >
          {title}
          {title.endsWith("Brains for Robots") && (
            <sup style={{ fontSize: "1rem", fontWeight: "600" }}>™</sup>
          )}
        </Typography>
      )}

      {subtitle && (
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: props.subtitleFontWeight,
            fontSize: props.subtitleFontSize,
            maxWidth: 700,
            display: "inline-block",
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default SectionHeader;
