"use client";
import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { updateTutorialProgress } from "../api/userManagement";
import CustomSnackbar from "../util/CustomSnackbar";
import SignupModal from "../util/SignupModal";

const TutorialCompletionActions = ({
  accessToken,
  action,
  chapterId,
  completed,
  setCompleted,
  setShowAdvanced,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [isAnswerRight, setIsAnswerRight] = useState(null);
  const type = action?.type;
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [signupModalType, setSignupModalType] = useState("signin");

  // Set selected radio answer
  const handleRadioChange = (event) => {
    setSelectedAnswer(event.target.value);
    setIsAnswerRight(null);
  };

  const handleComplete = async () => {
    try {
      if (accessToken) {
        setIsLoading(true);
        const res = await updateTutorialProgress(accessToken, chapterId);
        res?.data?.show_advanced && setShowAdvanced(true);
      }
      setCompleted(true);
    } catch (err) {
      console.error(err);
      setError("An error occurred sending your completion. Please retry.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle radio answer submission
  const handleRadioSubmit = async () => {
    try {
      const isRight =
        parseInt(selectedAnswer, 10) === action.correct_answer_index;
      if (isRight) {
        await handleComplete();
      }
      setIsAnswerRight(isRight);
    } catch (err) {
      console.error(err);
      setError("An error occurred sending your completion. Please retry.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle completion button click
  const handleButtonClick = async () => {
    try {
      await handleComplete();
      setIsAnswerRight(true);
    } catch (err) {
      console.error(err);
      setError("An error occurred sending your completion. Please retry.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        mb: 6,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Error snackbar */}
      {error && (
        <CustomSnackbar
          message={error}
          setMessage={setError}
          severity="error"
          autoHideDuration={5000}
        />
      )}

      {/* Header */}
      <Typography
        sx={{
          fontSize: "clamp(1rem, 2vw, 1.5rem)",
          fontWeight: "700",
          alignSelf: "start",
        }}
      >
        {completed ? "Section Complete" : "Complete Section"}
      </Typography>
      {completed && !isAnswerRight ? (
        <Alert severity="success" variant="outlined" sx={{ mt: 2 }}>
          You&apos;ve completed this section!
        </Alert>
      ) : (
        <>
          {/* Quiz */}
          {!isAnswerRight && (
            <Typography
              sx={{
                mt: 2,
                mb: 4,
                alignSelf: "start",
                color: "accents.light",
              }}
            >
              All done with this section?{" "}
              {type === "radio" ? "Answer the question" : "Click the button"}{" "}
              below to mark it as complete.
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: { xs: 1, md: 2 },
              ...(!isAnswerRight &&
                type === "radio" && {
                  backgroundColor: "background.paper",
                  border: 1,
                  borderColor: "primary.main",
                  borderRadius: "5px",
                }),
            }}
          >
            {type === "radio" && (
              <>
                {!isAnswerRight && (
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      sx={{
                        fontSize: { xs: "1rem", md: "1.2rem" },
                        fontWeight: "600",
                        color: "white",
                        "&.Mui-focused": {
                          color: "white",
                        },
                      }}
                    >
                      {action.question}
                    </FormLabel>
                    {isAnswerRight === false && (
                      <Alert
                        severity="error"
                        variant="outlined"
                        sx={{
                          mt: 2,
                          py: 0,
                          display: { xs: "flex", md: "none" },
                          verticalAlign: "middle",
                        }}
                      >
                        Try again!
                      </Alert>
                    )}
                    <RadioGroup
                      name="quiz-options"
                      value={selectedAnswer}
                      onChange={handleRadioChange}
                      sx={{ my: 2 }}
                    >
                      {action.answers.map((answer, index) => (
                        <Box
                          key={index}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <FormControlLabel
                            value={index.toString()}
                            control={<Radio />}
                            label={
                              <Box
                                sx={{
                                  display: "inline",
                                }}
                              >
                                <Typography
                                  component="span"
                                  sx={{
                                    py: { xs: 0, md: "2px" }, // keeps Alert from changing the line height
                                    fontSize: { xs: ".9rem", md: "1rem" },
                                  }}
                                >
                                  {answer}
                                </Typography>
                                {isAnswerRight === false &&
                                  parseInt(selectedAnswer, 10) === index && (
                                    <Alert
                                      severity="error"
                                      variant="outlined"
                                      sx={{
                                        display: {
                                          xs: "none",
                                          md: "inline-flex",
                                        },
                                        verticalAlign: "middle",
                                        ml: 1,
                                        py: 0,
                                        ".MuiAlert-icon": {
                                          py: "2px",
                                        },
                                        ".MuiAlert-message": {
                                          py: "2px",
                                        },
                                      }}
                                    >
                                      Try again!
                                    </Alert>
                                  )}
                              </Box>
                            }
                            sx={{
                              alignItems: "flex-start",
                              my: 0,
                              ".MuiFormControlLabel-label": {
                                minHeight: "42px",
                                display: "flex",
                                alignItems: "center",
                              },
                            }}
                          />
                        </Box>
                      ))}
                    </RadioGroup>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleRadioSubmit}
                      disabled={isLoading}
                      sx={{ alignSelf: "center" }}
                    >
                      {isLoading ? <CircularProgress size={24} /> : "Submit"}
                    </Button>
                  </FormControl>
                )}
              </>
            )}

            {/* Complete action (button type) */}
            {(!type || type === "button") && !isAnswerRight && (
              <Button
                variant="contained"
                onClick={handleButtonClick}
                disabled={isLoading}
                sx={{ alignSelf: "center" }}
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  action?.button_text || "Done"
                )}
              </Button>
            )}

            {/* Success message */}
            {isAnswerRight && (
              <Alert severity="success" variant="outlined">
                {type === "radio" ? "You got it!" : "Well done!"} You&apos;re
                one step further in your neuroscience & AGI journey.{" "}
                {!accessToken && (
                  <>
                    <Link
                      onClick={() => setSignupModalOpen(true)}
                      sx={{ cursor: "pointer" }}
                    >
                      Create an account
                    </Link>{" "}
                    or{" "}
                    <Link
                      onClick={() => {
                        setSignupModalType("signin");
                        setSignupModalOpen(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      login
                    </Link>{" "}
                    to save your progress.
                  </>
                )}
              </Alert>
            )}
          </Box>
        </>
      )}

      {/* Signup modal */}
      {signupModalOpen && (
        <SignupModal
          open={signupModalOpen}
          setOpen={setSignupModalOpen}
          authType={signupModalType}
        />
      )}
    </Box>
  );
};

export default TutorialCompletionActions;
