import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";

// Get tutorial slides etc.
export async function getTutorialData(
  token = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    if (!token) throw new Error("Missing any token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/tutorials/outline`,
      "GET",
      null,
      { Authorization: token }
    );

    logger("Response in getTutorialData function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getTutorialData function:", error);
    throw error;
  }
}
